import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("Agende uma Visita!");

  useEffect(() => {
    if (props.text !== undefined) {
      setText(props.text);
    }
  }, [props.text]);

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/5571988494399?text=Olá,%20Gostaria%20de%20mais%20informações"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>

        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
