import { useState } from "react";
import "./styles.css";

import icon from "../../assets/icontel.png";

const CtaTel = (props) => {
  const [text, setText] = useState("(71) 98849-4399");

  return (
    <button id="ctaTel">
      <a href="tel:988494399">
        <figure>
          <img src={props.icon || icon} alt="Ícone do Telefone" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaTel;
