import React from "react";
import "./index.css";

import CtaTel from "../CTATel";
import CtaWpp from "../CTAWpp";
import CtaUnidades from "../CTAUnidades";

const CtaTelWpp = (props) => {
  return (
    <article id="CtaTelWpp">
      <CtaTel icon={props.icon} />
      <CtaWpp text={"(71) 98849-4399"} />
      {props.hasUnity ? <CtaUnidades /> : ""}
    </article>
  );
};

export default CtaTelWpp;
